@media only screen and (min-width: 768px) {
  .App {
    position: relative;
    padding: 0 32px 96px;
  }
}

@media only screen and (min-width: 991px) {
  .App {
    position: relative;
    padding: 0 64px 96px;
  }
}
