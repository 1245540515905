@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype'), local('Arial');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype'), local('Arial');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype'), local('Arial');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype'), local('Arial');
    font-weight: 700;
    font-style: normal;
}