@import './assets/css/fonts.css';
@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html, body, #root, .App {
  min-height: calc(100vh - 72px) !important;
}

body, body * {
  font-family: Poppins, sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, ul, li {
  margin: 0;
  padding: 0;
  font-weight: unset;
  font-size: unset;
}

li {
  list-style-type: none;
}

.image-gallery {
  height: 360px !important;
  overflow: hidden !important;
}

.image-gallery-content {
  height: 360px !important;
}

.image-gallery-slide-wrapper {
  height: 360px !important;
  display: flex;
  align-items: center;
}

.image-gallery-icon {
  color: white !important;
}

.image-gallery-svg {
  width: 30px !important;
  height: 60px !important;
}
